import React from "react";
import { Link } from "react-router-dom";

const ListaProdutos = ({ produtos, loading, error }) => {
  const baseUrl = process.env.REACT_APP_API_URL; // Get base URL from .env file

  // Handle loading and error states
  if (loading) return <p>A carregar produtos em destaque...</p>;
  if (error) return <p>Erro ao carregar os dados: {error.message}</p>;

  return (
    <div className="lista-produtos-section col-12 col-t-12 col-d-10">
      <div className="lista-produtos-container">
        {produtos.length > 0 ? produtos.map((produto) => (
          <Link 
            to={`/carros/${produto.attributes.marca}/${produto.id}`} 
            key={produto.id} 
            className="produtos-destaque-item"
          >
            <div className="produtos-destaque-item-imagem">
              {/* Display the first image */}
              {produto.attributes.imagens.data && produto.attributes.imagens.data.length > 0 && (
                <img 
                  src={`${baseUrl}${produto.attributes.imagens.data[0].attributes.url}`} 
                  alt={produto.attributes.nome || 'Imagem do produto'} 
                />
              )}
            </div>
            <div className="produtos-destaque-item-conteudo">
              <div className="produtos-destaque-item-nome">
                <h3>{produto.attributes.nome}</h3>
              </div>
              <div className="produtos-destaque-item-caracteristicas">
                <span>{produto.attributes.ano}</span>
                <span>{produto.attributes.quilometros} km</span>
                <span>{produto.attributes.combustivel}</span>
                <span>{produto.attributes.potencia} cv</span>
                <span className="preco">{produto.attributes.preco} €</span>
              </div>
            </div>
          </Link>
        )) : (
          <p className="aviso-texto">Nenhum produto encontrado com essas características.</p>
        )}
      </div>
    </div>
  );
}

export default ListaProdutos;