import React, { useState, useEffect } from 'react';
import '../style.css';
import axios from 'axios';
import Menu from '../components/menu/Menu';
import PageTitle from '../components/pageTitle/PageTitle';
import ToTop from '../components/toTop/ToTop';
import Footer from '../components/footer/Footer';
import ListaProdutos from '../components/listaProdutos/ListaProdutos';
import FiltroProdutos from '../components/filtroProdutos/FiltroProdutos';
import CoverImage from '../assets/carros_cover.jpg'
import ContactoProduto from '../components/contactoProduto/ContactoProduto';

function Produtos() {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);

  const baseUrl = process.env.REACT_APP_API_URL; // Get base URL from .env file

  useEffect(() => {
    const fetchData = async () => {
      try {
        const produtosResponse = await axios.get(`${baseUrl}/api/carros`, {
          params: {
            populate: 'imagens',
            sort: ['createdAt:desc'],
            pagination: {
              limit: 12
            }
          }
        });

        setProdutos(produtosResponse.data.data);
        setProdutosFiltrados(produtosResponse.data.data); // Inicialmente, mostrar todos os produtos
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl]);

  const aplicarFiltros = (filtros) => {
    let filtrados = produtos;

    if (filtros.marca) {
      filtrados = filtrados.filter(produto => produto.attributes.marca === filtros.marca);
    }

    if (filtros.precoMin || filtros.precoMax) {
      filtrados = filtrados.filter(produto => {
        const preco = parseInt(produto.attributes.preco, 10);
        return (
          (!filtros.precoMin || preco >= filtros.precoMin) &&
          (!filtros.precoMax || preco <= filtros.precoMax)
        );
      });
    }

    if (filtros.anoMin || filtros.anoMax) {
      filtrados = filtrados.filter(produto => {
        const ano = parseInt(produto.attributes.ano, 10);
        return (
          (!filtros.anoMin || ano >= filtros.anoMin) &&
          (!filtros.anoMax || ano <= filtros.anoMax)
        );
      });
    }

    if (filtros.combustivel) {
      filtrados = filtrados.filter(produto => produto.attributes.combustivel === filtros.combustivel);
    }

    // Filtros avançados
    if (filtros.cor) {
      filtrados = filtrados.filter(produto => produto.attributes.cor === filtros.cor);
    }

    if (filtros.quilometrosMin || filtros.quilometrosMax) {
      filtrados = filtrados.filter(produto => {
        const quilometros = parseInt(produto.attributes.quilometros, 10);
        return (
          (!filtros.quilometrosMin || quilometros >= filtros.quilometrosMin) &&
          (!filtros.quilometrosMax || quilometros <= filtros.quilometrosMax)
        );
      });
    }

    if (filtros.potencia) {
      filtrados = filtrados.filter(produto => produto.attributes.potencia >= filtros.potencia);
    }

    if (filtros.potenciaMin) {
      filtrados = filtrados.filter(produto => {
        const potencia = parseInt(produto.attributes.potencia, 10);
        return (
          (!filtros.potenciaMin || potencia >= filtros.potenciaMin)
        );
      });
    }

    if (filtros.tipoCaixa) {
      filtrados = filtrados.filter(produto => produto.attributes.tipo_caixa === filtros.tipoCaixa);
    }

    if (filtros.tracao) {
      filtrados = filtrados.filter(produto => produto.attributes.tracao === filtros.tracao);
    }

    if (filtros.numPortas) {
      filtrados = filtrados.filter(produto => produto.attributes.num_portas == filtros.numPortas);
    }

    if (filtros.segmento) {
      filtrados = filtrados.filter(produto => produto.attributes.segmento === filtros.segmento);
    }
    // Aplicar os filtros ao conjunto de produtos
    setProdutosFiltrados(filtrados);
  };

  return (
    <>
      <Menu />
      <PageTitle title="Automóveis" image={CoverImage} text="Encontre o carro perfeito para si ou para a sua empresa." />
      <div className="produtos-section gridrowfull">
        <FiltroProdutos produtos={produtos} aplicarFiltros={aplicarFiltros} />
        <ListaProdutos produtos={produtosFiltrados} loading={loading} error={error} />
      </div>
      <ContactoProduto />
      <Footer />
      <ToTop />
    </>
  );
  }

  export default Produtos;