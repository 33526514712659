import React from "react";
import Button from "../button/Button";

const Ligue = () => {
    return (
        <section className="ligue-section gridrowfull">
            <div className="ligue-container col-12">
                <div className="ligue-content col-12 col-t-12 col-d-7">
                    <h2>Precisa de ajuda imediata?</h2>
                    <p>
                        Estamos sempre disponíveis para responder às suas dúvidas. Ligue-nos agora e fale diretamente com a nossa equipa de suporte. Estamos aqui para ajudar em tudo o que precisar.
                    </p>
                    <a href="tel:+351123456789" className="ligue-button">
                        <Button name={'Ligar já'}/>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Ligue;