import React from "react";

const PageTitle = ({ title, image, text }) => {
    return (
        <section className="pagetitle-section gridrowfull">
            {image && <img src={image} alt={title} />}
            <h1>{title}</h1>
            <p>{text}</p>
        </section>
    );
}

export default PageTitle;