import '../style.css';
import Menu from '../components/menu/Menu';
import Footer from '../components/footer/Footer';
import ToTop from '../components/toTop/ToTop';
import Cover from '../components/cover/Cover';
import ProdutosDestaque from '../components/produtoDestaque/ProdutoDestaque';
import UltimasEntradas from '../components/ultimasEntradas/UltimasEntradas';
import NumerosEmpresa from '../components/numerosEmpresa/NumerosEmpresa';
import ContactoProduto from '../components/contactoProduto/ContactoProduto';

function Home() {
  return (
    <>
        <Menu />
        <Cover />
        <ProdutosDestaque />
        <UltimasEntradas />
        <NumerosEmpresa />
        <ContactoProduto />
        <ToTop />
        <Footer />
    </>
  );
}

export default Home;