import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Menu from '../components/menu/Menu';
import ToTop from '../components/toTop/ToTop';
import Footer from '../components/footer/Footer';
import GaleriaProduto from "../components/galeriaProduto/GaleriaProduto";
import InfoAnuncio from '../components/infoAnuncio/InfoAnuncio'; // Ensure this path is correct
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import CaracteristicasProdutos from "../components/caracteristicasProdutos/CaracteristicasProdutos";
import DescricaoProduto from "../components/descricaoProduto/DescricaoProduto";
import SobreNos from "../components/sobreNos/SobreNos";
import ContactoProduto from "../components/contactoProduto/ContactoProduto";

const ProdutoIndividual = () => {
    const [produto, setProduto] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { produtoId } = useParams();
    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch product details with all related data
                const produtoResponse = await axios.get(`${baseUrl}/api/carros/${produtoId}`, {
                    params: {
                        populate: 'imagens',
                    }
                });
                setProduto(produtoResponse.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [produtoId, baseUrl]);

    if (loading) return <p>Carregando produto...</p>;
    if (error) return <p>Erro ao carregar o produto: {error.message}</p>;
    if (!produto) return <p>Produto não encontrado.</p>;

    // Prepare imageGallery from produto attributes
    const imageGallery = produto.attributes.imagens?.data || [];
    const name = produto.attributes.nome || 'Produto';
    const ano = produto.attributes.ano || 'N/A';
    const quilometros = produto.attributes.quilometros || 'N/A';
    const combustivel = produto.attributes.combustivel || 'N/A';
    const potencia = produto.attributes.potencia || 'N/A';
    const num_portas = produto.attributes.num_portas || 'N/A';
    const preco = produto.attributes.preco || 'N/A';

    return (
        <>
            <Menu />
            <Breadcrumbs />
            <section className="gridrow">
                <GaleriaProduto 
                    imageGallery={imageGallery}
                    name={name}
                />
                <InfoAnuncio
                    nome={name}
                    ano={ano}
                    quilometros={quilometros}
                    combustivel={combustivel}
                    potencia={potencia}
                    num_portas={num_portas}
                    preco={preco}
                />
            </section>
            <CaracteristicasProdutos produto={produto} />
            <DescricaoProduto descricao={produto.attributes.descricao} />
            <SobreNos />
            <ContactoProduto />
            <Footer />
            <ToTop />
        </>
    );
};

export default ProdutoIndividual;