import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import QuemSomos from './pages/QuemSomos';
import Contactos from './pages/Contactos';
import Produtos from './pages/Produtos';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import ProdutoIndividual from './pages/ProdutoIndividual';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import './style.css';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula um carregamento de 2 segundos
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quem-somos" element={<QuemSomos />} />
          <Route path="/contactos" element={<Contactos />} />
          <Route path="/carros" element={<Produtos />} />
          <Route path="/carros/:marca/:produtoId" element={<ProdutoIndividual />} />
          <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;