import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";

const InfoAnuncio = ({ nome, ano, quilometros, combustivel, potencia, num_portas, preco }) => {
    return (
        <div className="info-anuncio-container col-12 col-t-12 col-d-4">
            <div className="info-anuncio-nome col-12">
                <h1>{nome}</h1>
            </div>
            <div className="info-anuncio-breve col-12">
                <span>Ano: {ano}</span>
                <span>Km's: {quilometros}</span>
                <span>Combustível: {combustivel}</span>
                <span>Cavalos: {potencia}</span>
                <span>Portas: {num_portas}</span>
            </div>
            <div className="info-anuncio-preco col-12">
                <span>{preco}€</span>
            </div>
            <div className="ver-mais-caracteristicas col-12">
                <a href="#caracteristicas">Ver mais caracteristicas</a>
            </div>
            <div className="contactar-button col-12">
                <a href="#contactar">
                    <Button className="contactar" name={'Contactar'} />
                </a>
            </div>
            
        </div>
    );
};

export default InfoAnuncio;