import React from "react";

const InfoContactos = () => {
    return(
        <section className="info-contactos-section gridrow">
            <div className="info-contactos-container col-12 col-t-12 col-d-6">
                <div className="info-contactos-texto col-12 col-t-12 col-d-12">
                    <h2>Estamos prontos para o apoiar!</h2>
                    <p className="col-12">
                        Para uma experiência rápida e eficiente, sugerimos que utilize os nossos canais digitais, disponíveis 24/7 para te ajudar em qualquer momento e lugar.
                    </p>
                </div>
                <div className="info-contactos-texto col-12 col-t-12 col-d-12">
                    <h2>Contacte-nos no horário mais conveniente!</h2>
                    <p className="col-12">
                        Sabemos que os nossos clientes procuram-nos ao longo do dia, por isso partilhamos os horários em que a nossa resposta é mais rápida e eficaz.
                    </p>
                    <p className="col-12">De segunda a sábado das 9h às 18h.</p>
                </div>
            </div>
            <div className="info-contactos-localizacao col-12 col-t-12 col-d-6">
                <div className="info-contactos-localizacao-mapa col-12">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345096167!2d144.95373631531677!3d-37.81627974202144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43b1f5fd81%3A0xb1204b3f5f106b57!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sus!4v1632163692866!5m2!1sen!2sus"
                        width="100%"
                        height="400"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps"
                    ></iframe>
                </div>
            </div>
        </section>
    );
}

export default InfoContactos;