import '../style.css';
import Menu from '../components/menu/Menu';
import PageTitle from '../components/pageTitle/PageTitle';
import ToTop from '../components/toTop/ToTop';

function PoliticaPrivacidade() {
  return (
    <>
        <Menu />
        <PageTitle title="Politica Privacidade" />
        <ToTop />
    </>
  );
}

export default PoliticaPrivacidade;