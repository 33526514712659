import React, { useState } from "react";

const FiltroProdutos = ({ produtos, aplicarFiltros }) => {
    const [mostrarAvancados, setMostrarAvancados] = useState(false);
    const [filtros, setFiltros] = useState({
        marca: "",
        precoMin: "",
        precoMax: "",
        anoMin: "",
        anoMax: "",
        ano: "",
        combustivel: "",
        cor: "",
        quilometrosMin: "",
        quilometrosMax: "",
        potenciaMin: "",
        tipoCaixa: "",
        tracao: "",
        numPortas: "",
        segmento: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const novosFiltros = {
            ...filtros,
            [name]: value,
        };
        setFiltros(novosFiltros);
        aplicarFiltros(novosFiltros); // Aplica os filtros imediatamente após a mudança
    };

    const handleResetFilters = () => {
        const filtrosResetados = {
            marca: "",
            precoMin: "",
            precoMax: "",
            anoMin: "",
            anoMax: "",
            combustivel: "",
            cor: "",
            quilometrosMin: "",
            quilometrosMax: "",
            potenciaMin: "",
            tipoCaixa: "",
            tracao: "",
            numPortas: "",
            segmento: "",
        };
        setFiltros(filtrosResetados);
        aplicarFiltros(filtrosResetados); // Aplica os filtros resetados
    };

    return (
        <div className="filtro-produtos-container col-12 col-t-12 col-d-2">
            <form>
                <h3>Filtrar Produtos</h3>
                {/* Filtros principais */}
                <div className="filtro-principal">
                    <label>
                        Marca:
                        <select name="marca" value={filtros.marca} onChange={handleInputChange}>
                            <option value="">Todas</option>
                            {[...new Set(produtos.map(p => p.attributes.marca))].map(marca => (
                                <option key={marca} value={marca}>{marca}</option>
                            ))}
                        </select>
                    </label>

                    <label>
                        Preço:
                        <input 
                            type="number" 
                            name="precoMin" 
                            placeholder="Min" 
                            value={filtros.precoMin} 
                            onChange={handleInputChange} 
                        />
                        <input 
                            type="number" 
                            name="precoMax" 
                            placeholder="Max" 
                            value={filtros.precoMax} 
                            onChange={handleInputChange} 
                        />
                    </label>

                    <label>
                        Ano:
                        <input 
                            type="number" 
                            name="anoMin" 
                            placeholder="Min" 
                            value={filtros.anoMin} 
                            onChange={handleInputChange} 
                        />
                        <input 
                            type="number" 
                            name="anoMax" 
                            placeholder="Max" 
                            value={filtros.anoMax} 
                            onChange={handleInputChange} 
                        />
                    </label>

                    <label>
                        Combustível:
                        <select name="combustivel" value={filtros.combustivel} onChange={handleInputChange}>
                            <option value="">Todos</option>
                            {[...new Set(produtos.map(p => p.attributes.combustivel))].map(combustivel => (
                                <option key={combustivel} value={combustivel}>{combustivel}</option>
                            ))}
                        </select>
                    </label>
                </div>

                {/* Botão para mostrar filtros avançados */}
                <button
                    type="button"
                    className="filtro-avancado-button"
                    onClick={() => setMostrarAvancados(!mostrarAvancados)}
                >
                    {mostrarAvancados ? "Ocultar Filtros Avançados" : "Filtros Avançados"}
                </button>

                {/* Filtros avançados */}
                {mostrarAvancados && (
                    <div className="filtro-avancado">
                        <label>
                            Cor:
                            <select name="cor" value={filtros.cor} onChange={handleInputChange}>
                                <option value="">Todas</option>
                                {[...new Set(produtos.map(p => p.attributes.cor))].map(cor => (
                                    <option key={cor} value={cor}>{cor}</option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Quilómetros:
                            <input 
                                type="number" 
                                name="quilometrosMin" 
                                placeholder="Min" 
                                value={filtros.quilometrosMin} 
                                onChange={handleInputChange} 
                            />
                            <input 
                                type="number" 
                                name="quilometrosMax" 
                                placeholder="Max" 
                                value={filtros.quilometrosMax} 
                                onChange={handleInputChange} 
                            />
                        </label>

                        <label>
                            Potência (CV):
                            <input 
                                type="number" 
                                name="potenciaMin"
                                placeholder="Min"  
                                value={filtros.potenciaMin} 
                                onChange={handleInputChange} 
                            />
                        </label>

                        <label>
                            Tipo de Caixa:
                            <select name="tipoCaixa" value={filtros.tipoCaixa} onChange={handleInputChange}>
                                <option value="">Todos</option>
                                {[...new Set(produtos.map(p => p.attributes.tipo_caixa))].map(tipoCaixa => (
                                    <option key={tipoCaixa} value={tipoCaixa}>{tipoCaixa}</option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Tração:
                            <select name="tracao" value={filtros.tracao} onChange={handleInputChange}>
                                <option value="">Todas</option>
                                {[...new Set(produtos.map(p => p.attributes.tracao))].map(tracao => (
                                    <option key={tracao} value={tracao}>{tracao}</option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Nº de Portas:
                            <input 
                                type="number" 
                                name="numPortas" 
                                value={filtros.numPortas} 
                                onChange={handleInputChange} 
                            />
                        </label>

                        <label>
                            Segmento:
                            <select name="segmento" value={filtros.segmento} onChange={handleInputChange}>
                                <option value="">Todos</option>
                                {[...new Set(produtos.map(p => p.attributes.segmento))].map(segmento => (
                                    <option key={segmento} value={segmento}>{segmento}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                )}

                {/* Botão de repor filtros */}
                <button type="button" onClick={handleResetFilters}>Repor Filtros</button>
            </form>
        </div>
    );
};

export default FiltroProdutos;