import React from "react";
import coverImage from '../../assets/cover.jpg'; // Import the image
import Button from "../button/Button";
import { Link } from "react-router-dom";  // Import Link from react-router-dom

const Cover = () => {
    return(
        <section className="cover-section gridrowfull">
            <div className="cover-image">
                <img src={coverImage} alt="Cover" />
            </div>
            <div className="cover-container col-12 col-t-12 col-d-8">
                <h1 className="col-12">Encontre o Carro dos Seus Sonhos Hoje</h1>
                <span className="col-12">Descubra uma vasta seleção de veículos que combinam desempenho, segurança e estilo. A sua jornada para o carro perfeito começa aqui.</span>
                <Link to="/carros"><Button name={'Ver Carros'}/></Link>
            </div>
        </section>
    );
}

export default Cover;