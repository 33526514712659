import React from "react";

const NumerosEmpresa = () => {
    return(
        <section className="numeros-empresa-section gridrowfull">
            <h3 className="numeros-empresa-titulo col-12">Stand em números</h3>
            <div className="numeros-empresa-container col-8">
                <div className="numeros-empresa-item col-12 col-t-6 col-d-4">
                    <span className="numero">+1000</span>
                    <span className="info-numero">Carros vendidos</span>
                </div>
                <div className="numeros-empresa-item col-12 col-t-6 col-d-4">
                    <span className="numero">+2000</span>
                    <span className="info-numero">Clientes satisfeitos</span>
                </div>
                <div className="numeros-empresa-item col-12 col-t-6 col-d-4">
                    <span className="numero">+200</span>
                    <span className="info-numero">Feedback</span>
                </div>
            </div>
        </section>
    );
}

export default NumerosEmpresa;