import React from "react";

const SobreNos = () => {

    return (
        <div className="sobre-nos-container gridrow">
            <div className="sobre-nos-conteudo col-12">
                <h2>Sobre Nós</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem, totam sed veritatis harum tenetur delectus blanditiis, accusamus iusto ducimus quas doloribus, commodi sit sunt facere deserunt est debitis dolore consectetur?</p>
            </div>
        </div>
    );
}

export default SobreNos;