import React from "react";

const DescricaoProduto = ({ descricao }) => {
    if (!descricao) return null;

    return (
        <div className="descricao-produto-container gridrow">
            <div className="descricao-produto-conteudo col-12">
                <h2>Descrição</h2>
                <p>{descricao}</p>
            </div>
        </div>
    );
}

export default DescricaoProduto;