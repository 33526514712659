import React from "react";
import carColorIcon from '../../assets/car_color_icon.png';
import carContractIcon from '../../assets/car_contract_icon.png';
import carDoorIcon from '../../assets/car_door_icon.png';
import carEngineIcon from '../../assets/car_engine_icon.png';
import carFlagIcon from '../../assets/car_flag_icon.png';
import carFuelIcon from '../../assets/car_fuel_icon.png';
import carGearsIcon from '../../assets/car_gears_icon.png';
import carIcon from '../../assets/car_icon.png';
import carKeyIcon from '../../assets/car_key_icon.png';
import carKmIcon from '../../assets/car_km_icon.png';
import carPowerIcon from '../../assets/car_power_icon.png';
import carSeatIcon from '../../assets/car_seat_icon.png';
import carTractionIcon from '../../assets/car_traction_icon.png';

const CaracteristicasProdutos = ({ produto }) => {
    if (!produto) return null;

    const {
        marca,
        modelo,
        segmento,
        ano,
        combustivel,
        origem,
        quilometros,
        potencia,
        tipo_caixa,
        cor,
        lotacao,
        num_portas,
        tracao,
        registos,
        cilindrada,
    } = produto.attributes;

    return (
        <div id="caracteristicas" className="caracteristicas-produtos gridrow">
            <div className="caracteristicas-produtos-container col-12">
                <h2>Características</h2>
                <div className="lista-caracteristicas">
                    <div className="caracteristica-item">
                        <img src={carIcon} alt="Marca" className="icon" />
                        <span className="label">Marca:</span>
                        <span className="valor">{marca}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carKeyIcon} alt="Modelo" className="icon" />
                        <span className="label">Modelo:</span>
                        <span className="valor">{modelo}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carFlagIcon} alt="Segmento" className="icon" />
                        <span className="label">Segmento:</span>
                        <span className="valor">{segmento}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carContractIcon} alt="Ano" className="icon" />
                        <span className="label">Ano:</span>
                        <span className="valor">{ano}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carFuelIcon} alt="Combustível" className="icon" />
                        <span className="label">Combustível:</span>
                        <span className="valor">{combustivel}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carFlagIcon} alt="Origem" className="icon" />
                        <span className="label">Origem:</span>
                        <span className="valor">{origem}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carKmIcon} alt="Quilómetros" className="icon" />
                        <span className="label">Quilómetros:</span>
                        <span className="valor">{quilometros} km</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carPowerIcon} alt="Potência" className="icon" />
                        <span className="label">Potência:</span>
                        <span className="valor">{potencia} cv</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carGearsIcon} alt="Tipo de Caixa" className="icon" />
                        <span className="label">Tipo de Caixa:</span>
                        <span className="valor">{tipo_caixa}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carColorIcon} alt="Cor" className="icon" />
                        <span className="label">Cor:</span>
                        <span className="valor">{cor}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carSeatIcon} alt="Lotação" className="icon" />
                        <span className="label">Lotação:</span>
                        <span className="valor">{lotacao} lugares</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carDoorIcon} alt="Número de Portas" className="icon" />
                        <span className="label">N.º de Portas:</span>
                        <span className="valor">{num_portas}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carTractionIcon} alt="Tração" className="icon" />
                        <span className="label">Tração:</span>
                        <span className="valor">{tracao}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carContractIcon} alt="N.º de Registos" className="icon" />
                        <span className="label">N.º de Registos:</span>
                        <span className="valor">{registos}</span>
                    </div>
                    <div className="caracteristica-item">
                        <img src={carEngineIcon} alt="Cilindrada" className="icon" />
                        <span className="label">Cilindrada:</span>
                        <span className="valor">{cilindrada} cm3</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaracteristicasProdutos;