import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';

const UltimasEntradas = () => {
    const [produtos, setProdutos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const produtosResponse = await axios.get(`${baseUrl}/api/carros`, {
                    params: {
                        sort: ['createdAt:desc'],
                        pagination: {
                            limit: 4
                        },
                        populate: '*',
                    }
                });

                setProdutos(produtosResponse.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [baseUrl]);

    if (loading) return <p>Carregando produtos em destaque...</p>;
    if (error) return <p>Erro ao carregar dados: {error.message}</p>;

    return (
        <section className="produtos-destaque-section gridrow">
            <div className="produtos-destaque-titulo col-12">
                <h2>Últimas Entradas</h2>
            </div>
            <div className="produtos-destaque-lista col-12">
                {produtos.length > 0 ? produtos.map((produto) => {
                    const imagens = produto.attributes.imagens?.data || [];
                    const imageUrl = imagens.length > 0
                        ? `${baseUrl}${imagens[0].attributes.url}`
                        : '';
                    
                    return (
                        <Link 
                            to={`/carros/${produto.attributes.marca}/${produto.id}`} 
                            key={produto.id} 
                            className="produtos-destaque-item"
                        >
                            <div className="produtos-destaque-item-imagem">
                                <img 
                                    src={imageUrl} 
                                    alt={produto.attributes.nome || 'Imagem do produto'} 
                                />
                            </div>
                            <div className="produtos-destaque-item-conteudo">
                                <div className="produtos-destaque-item-nome">
                                    <h3>{produto.attributes.nome}</h3>
                                </div>
                                <div className="produtos-destaque-item-caracteristicas">
                                    <span>{produto.attributes.ano}</span>
                                    <span>{produto.attributes.quilometros} km</span>
                                    <span>{produto.attributes.combustivel}</span>
                                    <span>{produto.attributes.potencia} cv</span>
                                    <span className="preco">{produto.attributes.preco} €</span>
                                </div>
                            </div>
                        </Link>
                    );
                }) : (
                    <p>Nenhum produto encontrado.</p>
                )}
            </div>
        </section>
    );
};

export default UltimasEntradas;