import '../style.css';
import Menu from '../components/menu/Menu';
import ToTop from '../components/toTop/ToTop';
import PageTitle from '../components/pageTitle/PageTitle';
import CoverImage from '../assets/contact_cover.jpg'
import IntroEmpresa from '../components/introEmpresa/IntroEmpresa';
import NumerosEmpresa from '../components/numerosEmpresa/NumerosEmpresa';
import ContactoProduto from '../components/contactoProduto/ContactoProduto';
import Footer from '../components/footer/Footer';

function Contactos() {
  return (
    <>
        <Menu />
        <PageTitle title="Quem Somos" image={CoverImage} text="Descubra o parceiro ideal para a sua mobilidade." />
        <IntroEmpresa />
        <NumerosEmpresa />
        <ContactoProduto />
        <Footer />
        <ToTop />
    </>
  );
}

export default Contactos;